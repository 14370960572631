import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import { Layout, SEO, SectionCenter, Title, InTheNewsCard } from "../components"
import { getCurrentPath } from "../redux/actions/pathAction"
import getItems from "../utils/in-the-news-items"
import useImages from "../hooks/use-inthenews-pics"

const InTheNews = ({ location, getCurrentPath }) => {
  useEffect(() => {
    getCurrentPath(location.pathname)
  }, [getCurrentPath, location.pathname])

  const items = getItems(useImages())

  const matchMediaStr = "(min-width: 768px)"
  const [reverse, setReverse] = useState(
    typeof window === "undefined"
      ? null
      : window.matchMedia(matchMediaStr).matches
      ? true
      : false
  )

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const handleResize = () => {
      setReverse(window.matchMedia(matchMediaStr).matches ? true : false)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return (
    <Layout>
      <SEO title="In The News" />
      <section
        css={css`
          padding: 5rem 0 7rem;
        `}
      >
        <Title
          title="iWareTravel In the News"
          fontColor="var(--clr-primary-300)"
          textTransform="none"
        />
        <SectionCenter>
          <CardWrapper>
            {items.map((i, idx) => (
              <InTheNewsCard
                {...i}
                key={idx}
                reverse={reverse && idx % 2 !== 0}
              />
            ))}
          </CardWrapper>
        </SectionCenter>
      </section>
    </Layout>
  )
}

const CardWrapper = styled.div`
  display: grid;
  row-gap: 2rem;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 3rem;
  }
`

export default connect(null, { getCurrentPath })(InTheNews)
